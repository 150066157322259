@import "color";

.header {
  display: flex;
  align-items: center;
  height: 100px;
  background-color: $white-color;
  box-shadow: $shadow;
  @media screen and (max-width: 767px) {
    height: 70px;
  }

  .wrapper {
    display: flex;
    align-items: center;
  }



  &__logo {
    position: relative;
    max-width: 215px;
    padding-left: 110px;
    @media screen and (max-width: 767px) {
      max-width: 170px;
      padding-left: 75px;
    }

    .logo-img {
      position: absolute;
      width: 90px;
      height: 115px;
      top: -20px;
      left: 0;
      object-fit: contain;
      @media screen and (max-width: 767px) {
        width: 65px;
        height: 82px;
        top: -13px;
      }
    }

    .logo-title {
      font-size: 2.6rem;
      font-weight: 700;
      @media screen and (max-width: 767px) {
        font-size: 1.6rem;
      }
    }

    .logo-subtitle {
      display: block;
      font-size: 1.4rem;
      font-weight: 400;
      @media screen and (max-width: 767px) {
        font-size: 1.2rem;
      }
    }
  }


  &__navigation {
    display: flex;
    align-items: center;
    width: 100%;
    @media screen and (max-width: 1199px) {
      position: fixed;
      flex-direction: column;
      height: calc(100% - 50px);
      padding-top: 90px;
      left: 50%;
      bottom: -100vh;
      border-radius: 6px;
      z-index: 50;
      transform: translateX(-50%);
      background-color: $white-color;
      box-shadow: $shadow;
      transition: .5s;
      overflow-y: auto;
    }
    @media screen and (max-width: 767px) {
      padding-top: 70px;
    }
    .menu {
      display: flex;
      align-items: center;
      @media screen and (max-width: 1199px) {
        flex-direction: column;
      }
    }

    .menu-item-has-children {
      &:after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        border-left: 2px solid $text-color;
        border-bottom: 2px solid $text-color;
        top: 12px;
        right: -20px;
        transform: rotate(-45deg);
        transition: .3s;
        @media screen and (max-width: 1199px) {
          right: 80px;
        }
      }
    }


    .menu-item {
      position: relative;
      display: block;
      margin-right: 65px;
      font-size: 1.6rem;
      font-weight: 500;
      &:last-child {
        margin-right: 0;
      }

      &:hover {
        .sub-menu {
          max-height: 500px;
          padding: 10px 20px;
          @media screen and (max-width: 1199px) {
            border-top: 2px solid #DDDDDD;
            border-bottom: 2px solid #DDDDDD;
          }
        }
        &:after {
          top: 18px;
          transform: rotate(135deg);
          border-left: 2px solid $decor-color;
          border-bottom: 2px solid $decor-color;
        }
      }
      @media screen and (max-width: 1400px) {
        margin-right: 50px;
      }
      @media screen and (max-width: 1199px) {
        width: 100%;
        margin: 5px 0;
        font-size: 2.4rem;
        font-weight: 400;
      }
      @media screen and (max-width: 767px) {
        font-size: 1.6rem;
        margin: 0;
      }

      &__link {
        display: block;
        text-align: center;
        width: 100%;
        padding: 10px 0;
        &:hover {
          color: $decor-color;
        }
        @media screen and (max-width: 767px) {
          padding: 8px 0;
        }
      }

      .sub-menu {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: start;
        width: 180px;
        max-height: 0;
        padding: 0 20px;
        border-radius: 6px;
        left: -20px;
        z-index: 20;
        background-color: $white-color;
        box-shadow: $shadow;
        overflow: hidden;
        transition: .5s;
        @media screen and (max-width: 1199px) {
          position: relative;
          box-shadow: none;
          width: 100%;
          left: auto;
          border-top: 2px solid transparent;
          border-bottom: 2px solid transparent;
        }
        .menu-item {
          margin: 5px 0;
        }
      }
    }
    &.active {
      bottom: 50px;
    }
  }

  &__navigation-list {
    margin: 0 auto;
    @media screen and (max-width: 1600px) {
      margin-left: 90px;
    }
    @media screen and (max-width: 1400px) {
      margin: 0 auto;
    }
    @media screen and (max-width: 1199px) {
      order: 1;
      width: 100%;
      max-width: 320px;
      margin: 0;
    }
  }

  &__email {
    font-size: 1.6rem;
    font-weight: 500;
    &:hover {
      color: $decor-color;
    }
    @media screen and (max-width: 1600px) {
      display: none;
    }
    @media screen and (max-width: 1199px) {
      display: block;
      order: 3;
      padding:  10px 0;
      margin-bottom: 50px;
      font-weight: 400;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: 25px;
    }
  }

  &__btn {
    margin-left: 20px;
    padding: 16px 20px;
    @media screen and (max-width: 1600px) {
      margin-left: 0;
    }
    @media screen and (max-width: 1199px) {
      order: 4;
      width: 230px;
      margin-bottom: 20px;
    }
    @media screen and (max-width: 767px) {
      width: 240px;
      padding: 10px 20px;
    }
  }

  .btn-callback {
    @media screen and (max-width: 1600px) {
      display: none;
    }
    @media screen and (max-width: 1199px) {
      display: flex;
    }
  }

  &__phone {
    display: flex;
    flex-direction: column;
    margin-left: 45px;
    @media screen and (max-width: 1600px) {
      margin-left: 35px;
    }
    @media screen and (max-width: 1400px) {
      margin-left: 20px;
    }
    @media screen and (max-width: 1199px) {
      order: 2;
      margin-left: 0;
      font-weight: 400;
    }
  }

  &__phone-item {
    font-size: 1.6rem;
    font-weight: 500;
    &:hover {
      color: $decor-color;
    }
    @media screen and (max-width: 1199px) {
      padding: 10px 0;
      margin-bottom: 15px;
      font-weight: 400;
    }
    @media screen and (max-width: 767px) {
      font-size: 1.4rem;
    }
    &:nth-child(2n) {
      display: none;
      @media screen and (max-width: 1199px) {
        display: block;
      }
    }
  }


  &__whatsapp {
    position: relative;
    width: 50px;
    height: 50px;
    margin-left: 40px;
    border-radius: 50%;
    border: 1px solid $decor-color;
    transition: .3s;
    @media screen and (max-width: 1400px) {
      margin-left: 30px;
    }
    @media screen and (max-width: 1199px) {
      margin: 0;
    }
    &:before {
      content: '';
      position: absolute;
      background-image: url("../img/whatsapp.svg");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 50%;
      height: 50%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    &:hover {
      background-color: $decor-color;
    }
    @media screen and (max-width: 1199px) {
      display: none;
    }
  }

  //&__instagram {
  //  display: none;
  //  width: 50px;
  //  height: 50px;
  //  background-image: url("../img/instagram.svg");
  //  background-position: center;
  //  background-size: 50%;
  //  background-repeat: no-repeat;
  //  @media screen and (max-width: 1199px) {
  //    display: block;
  //    order: 5;
  //  }
  //}

  .menu-close {
    top: 40px;
    right: 60px;
    @media screen and (max-width: 767px) {
      width: 35px;
      height: 35px;
      top: 20px;
      right: 20px;
    }
  }

  &__mobile {
    display: none;
    @media screen and (max-width: 1199px) {
      display: flex;
      flex-direction: column;
      margin-left: auto;
    }
    .header__phone-item {
      @media screen and (max-width: 1199px) {
        margin: 0;
        padding: 0;
      }
      @media screen and (max-width: 767px) {
        font-size: 1.2rem;
        //margin: 0;
        //padding: 0;
      }
    }
  }
}