@import "color";

.slider-wrapper {
  position: relative;
  @media screen and (max-width: 1199px) {
    width: fit-content;
    margin: 0 auto;
  }
}

.slider {
  max-width: 1480px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  overflow: hidden;
  @media screen and (max-width: 1600px) {
    max-width: 1100px;
  }
  @media screen and (max-width: 1400px) {
    max-width: 1000px;
  }
  @media screen and (max-width: 1199px) {
    max-width: 940px;
    padding: 0;
  }
  @media screen and (max-width: 767px) {
    max-width: 730px;
    padding: 0;
  }
}

.slider-item {
  width: 330px;
  height: 470px;
  border-radius: 6px;
  box-shadow: $shadow;
  overflow: hidden;
  @media screen and (max-width: 1400px) {
    width: 300px;
    height: 430px;
  }
  @media screen and (max-width: 767px) {
    width: 230px;
    height: 340px;
  }
}

.item-img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}


.button-prev,
.button-next {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 20;
  cursor: pointer;
  &:before {
    content: " ";
    position: absolute;
    width: 15px;
    height: 15px;
    top: 50%;
    transform: translate(0, -50%);
  }
  @media screen and (max-width: 1199px) {
    display: none;
  }
}

.button-prev {
  left: 20px;
  &:before {
    left: 20px;
    border-left: 2px solid $decor-color;
    border-bottom: 2px solid $decor-color;
    transform: translate(0, -50%) rotate(45deg);
  }
  &:hover {
    &:before {
      border-left: 2px solid $white-color;
      border-bottom: 2px solid $white-color;
    }
  }
}
.button-next {
  right: 20px;
  &:before {
    right: 20px;
    border-right: 2px solid $decor-color;
    border-bottom: 2px solid $decor-color;
    transform: translate(0, -50%) rotate(-45deg);
  }
  &:hover {
    &:before {
      border-right: 2px solid $white-color;
      border-bottom: 2px solid $white-color;
    }
  }

}

.swiper-button-disabled {
  cursor: auto;
  opacity: .5;
}