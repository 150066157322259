@import "color";

.delivery-banner,
.service-banner {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 95px 75px;
  border-radius: 6px;
  top: 0;
  left: 0;
  z-index: 15;
  transform: scale(0);
  opacity: 0;
  background-color: $decor-color;
  transition: .3s, opacity .4s;
  @media screen and (max-width: 1400px) {
    padding: 110px 75px;
  }
  @media screen and (max-width: 1199px) {
    padding: 75px 45px 85px;
  }
  @media screen and (max-width: 767px) {
    padding: 15px;
  }
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $image-decor;
    z-index: 1;
  }
  &.active {
    transform: scale(1);
    opacity: 1;
  }

  .banner-btn {

  }

  &__title {
    color: $white-color;
    z-index: 5;
    @media screen and (max-width: 1600px) {
      max-width: 60%;
    }
    @media screen and (max-width: 1400px) {
      max-width: 55%;
    }
    @media screen and (max-width: 1199px) {
      max-width: 85%;
    }
  }

  &__desc {
    max-width: 440px;
    font-size: 2.4rem;
    color: $white-color;
    z-index: 5;
    @media screen and (max-width: 1199px) {
      display: none;
    }
  }

  &__btn {
    width: 285px;
    height: 70px;
    z-index: 5;
    @media screen and (max-width: 1199px) {
      margin-top: 60px;
    }
    @media screen and (max-width: 767px) {
      width: fit-content;
      height: fit-content;
      margin-top: 15px;
      background-color: transparent;
      color: $btn-color;
      font-size: 1.6rem;
    }
  }

  &__img {
    position: absolute;
    width: 880px;
    height: 520px;
    top: 0;
    right: -250px;
    object-fit: contain;
    @media screen and (max-width: 767px) {
      width: 200px;
      height: 120px;
      top: -20px;
      right: -75px;
    }
  }
}
