@import "color";


.footer {
  padding: 50px 0;
  @media screen and (max-width: 1400px) {
    padding: 40px 0;
  }
  @media screen and (max-width: 1199px) {
    padding: 35px 0 80px;
  }
  @media screen and (max-width: 767px) {
    padding: 25px 0 60px;
  }

  &__title {
    margin-bottom: 55px;
  }

  &__wrapper {
    display: flex;
    @media screen and (max-width: 1199px) {
      flex-direction: column;
    }
  }

  &__map {
    height: 100%;
    max-height: 470px;
    flex: 3;
    border-radius: 6px;
    box-shadow: $shadow;
    overflow: hidden;
    @media screen and (max-width: 1199px) {
      flex: 1;
      max-height: 410px;
    }
    @media screen and (max-width: 767px) {
      max-height: 280px;
    }
  }

  &__contacts {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
    flex: 1;
    @media screen and (max-width: 1199px) {
      position: relative;
      flex-direction: row;
      justify-content: space-between;
      align-items: start;
      margin-top: 50px;
    }
    @media screen and (max-width: 767px) {
      margin-top: 30px;
    }
  }

  &__contact-address {
    display: flex;
    flex-direction: column;
    align-items: end;
    text-align: end;
    max-width: 250px;
    margin-bottom: auto;
    font-size: 1.6rem;
    @media screen and (max-width: 1199px) {
      order: 2;
      align-items: start;
      text-align: start;
      max-width: 270px;
      margin-left: 60px;
      margin-right: auto;
    }
    @media screen and (max-width: 767px) {
      display: none;
    }
    .time-title {
      margin: 45px 0 25px;
      @media screen and (max-width: 1199px) {
        display: none;
      }
    }

    .time-item {
      @media screen and (max-width: 1199px) {
        display: none;
      }
    }

    .mobile-time-item {
      display: none;
      @media screen and (max-width: 1199px) {
        display: block;
        margin-top: 15px;
      }
    }

  }


  &__contact-social {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    @media screen and (max-width: 1199px) {
      order: 3;
      flex-direction: row;
      margin-bottom: 0;
    }
  }

  &__contact-whatsapp,
  &__contact-instagram {
    width: 26px;
    height: 26px;
  }

  &__contact-whatsapp {
    margin-bottom: 40px;
    background-image: url("../img/whatsapp.svg");
    background-size: contain;
    background-repeat: no-repeat;
    @media screen and (max-width: 1199px) {
      order: 2;
      margin-bottom: 0;
    }
  }

  &__contact-instagram {
    background-image: url("../img/instagram.svg");
    background-size: contain;
    background-repeat: no-repeat;
    @media screen and (max-width: 1199px) {
      order: 1;
      margin-right: 30px;
    }
    @media screen and (max-width: 767px) {
      margin-right: 20px;
    }
  }

  &__contact-link {
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1199px) {
      order: 1;
    }
    .link-item {
      font-size: 1.6rem;
      &:last-child {
        margin-top: 15px;
      }
      &:hover {
        color: $decor-color;
      }
    }
  }

  .diple {
    margin-top: 25px;
    font-size: 1.6rem;
    color: #bfbfbf;
    @media screen and (max-width: 1199px) {
      position: absolute;
      right: 0;
      bottom: 0;
      margin: 0;
    }

    &__hide {
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }

  .quick-menu {
    position: fixed;
    display: none;
    align-items: center;
    justify-content: space-between;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    padding: 0 70px;
    z-index: 50;
    background-color: $white-color;
    box-shadow: $shadow;
    @media screen and (max-width: 1199px) {
      display: flex;
    }
    @media screen and (max-width: 767px) {
      padding-left: 30px;
      padding-right: 20px;
    }

    &__item {
      display: block;
      width: 40px;
      height: 40px;
      background-size: 60%;
      background-position: center;
      background-repeat: no-repeat;
      &:first-child {
        background-image: url("../img/mail.svg");
      }
      &:nth-child(2) {
        background-image: url("../img/phone.svg");
      }
      &:nth-child(3) {
        background-image: url("../img/whatsapp.svg");
      }
      @media screen and (max-width: 767px) {
        width: 30px;
        height: 30px;
      }
    }
  }
}