@import "color";

.services {
  padding: 50px 0;
  @media screen and (max-width: 1400px) {
    padding-bottom: 30px;
  }
  @media screen and (max-width: 1199px) {
    padding-bottom: 45px;
  }
  @media screen and (max-width: 767px) {
    padding-bottom: 30px;
  }
}
