@import "color";

.modal-bg {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  display: none;
  z-index: 100;
  &.active {
    display: block;
  }
}

.modal {
  width: calc(100% - 40px);
  max-width: 540px;
  max-height: 80vh;
  overflow-y: auto;
  padding: 60px 100px 95px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  border-radius: 6px;
  background-color: $decor-color;
  transition: .3s;
  z-index: 110;
  &.active {
    transform: translate(-50%, -50%) scale(1);
  }
  @media screen and (max-width: 767px) {
    padding: 70px 20px 65px;
  }

  &__body {
    .modal-close {
      top: 20px;
      right: 20px;
    }
  }

  &__body-title {
    margin-bottom: 50px;
    font-size: 3.6rem;
    font-weight: 500;
    color: $white-color;
    text-align: center;
    @media screen and (max-width: 767px) {
      font-size: 2.4rem;
    }
  }

  .input {
    width: 100%;
    height: 50px;
    padding-left: 20px;
    margin-bottom: 20px;
    background-color: $white-color;
    border-radius: 4px;
    font-size: 1.4rem;

    color: $decor-color;
    &::placeholder {
      color: $decor-color;
      font-weight: 600;
    }
  }

  &__btn-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  .upload {
    display: flex;
    align-items: center;
    width: 150px;
    @media screen and (max-width: 767px) {
      max-width: 240px;
      width: 100%;
      margin-bottom: 20px;
    }

    &:hover {
      .upload__icon {
        background-color: $btn-color;
        path {
          fill: $decor-color;
        }
      }
    }

    &__icon {
      min-width: 50px;
      height: 50px;
      padding: 15px;
      margin-right: 20px;
      border-radius: 4px;
      box-shadow: 0 0 0 1px rgba(250, 205, 19, 1);
      transition: .3s;
      path {
        transition: .3s;
      }
    }

    &__name {
      font-size: 1.4rem;
      color: $white-color;
    }
  }

  &__submit {
    width: 165px;
    height: 50px;
    @media screen and (max-width: 767px) {
      max-width: 240px;
      width: 100%;
    }
  }

  .modalCloseBtn {
    margin: 0 auto;
  }

}
