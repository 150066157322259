@import "color";

.plug {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &__img {
    display: block;
    width: 150px;
    margin-bottom: 40px;
  }

  .title {

  }
}