@import "color";

h1.title {
  font-size: 6.8rem;
  font-weight: 500;
  @media screen and (max-width: 1400px) {
    font-size: 4.8rem;
  }

  @media screen and (max-width: 767px) {
    font-size: 1.8rem;
  }
}

h2.title {
  font-size: 6rem;
  font-weight: 500;
  @media screen and (max-width: 1199px) {
    font-size: 4.8rem;
  }
  @media screen and (max-width: 767px) {
    font-size: 1.8rem;
  }
}

h3.title {
  font-size: 3rem;
  font-weight: 500;
  @media screen and (max-width: 1199px) {
    font-size: 2.4rem;
  }
  @media screen and (max-width: 767px) {
    font-size: 2rem;
  }
}

