@import "color";
@import "title";
@import "buttons";
@import "container";
@import "banner";
@import "plug";
@import "header";
@import "main";
@import "footer";
@import "about";
@import "slider";
@import "services";
@import "modal";

* {

  box-sizing: border-box;

  &:after,
  &:before {
    transition: inherit;
    box-sizing: border-box;
  }
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
  font-family: 'Rubik', sans-serif;;
  background-color: $body-color;
  color: $text-color;
}

:root {
  font-size: 10px;
}

h1, h2, h3, h4, h5, h6, p, ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
  color: $text-color;
  transition: .3s;
}

input,
button,
textarea {
  outline: none;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: .3s;
}

.headline-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.img-wrapper {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $image-decor;
    z-index: 1;
  }
}


.main-block {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 110px 75px;
  @media screen and (max-width: 1400px) {
  }
  @media screen and (max-width: 1199px) {
    padding: 85px 45px;
  }
  @media screen and (max-width: 1199px) {
    padding: 15px 20px;
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $image-decor;
    z-index: -1;
  }

  &__title {
    width: 700px;
    margin-bottom: 25px;
    color: $white-color;
    text-transform: uppercase;
    @media screen and (max-width: 1400px) {
      width: 500px;
    }
    @media screen and (max-width: 1199px) {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  &__btn {
    width: 165px;
    height: 70px;
    @media screen and (max-width: 1199px) {
      height: 50px;
    }
    @media screen and (max-width: 767px) {
      width: fit-content;
      height: fit-content;
      background-color: transparent;
      color: $btn-color;
      font-size: 1.6rem;
    }
  }

  &__img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -5;
    object-fit: cover;
  }

}

.main-block__btn {
  width: 285px;
  @media screen and (max-width: 1199px) {
    height: 50px;
  }
}