@import "color";

.about {
  padding: 50px 0;
  @media screen and (max-width: 1400px) {
    padding-bottom: 30px;
  }
  @media screen and (max-width: 1199px) {
    padding-bottom: 45px;
  }
  @media screen and (max-width: 767px) {
    padding-bottom: 30px;
  }
}

.description {
  position: relative;
  padding: 50px 0;
  @media screen and (max-width: 1400px) {
    padding: 30px 0;
  }
  @media screen and (max-width: 1199px) {
    padding: 45px 0;
  }
  @media screen and (max-width: 767px) {
    padding: 30px 0;
  }
  &__decor {
    position: absolute;
    display: block;
    background-image: url("../img/medal.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 187px;
    height: 193px;
    top: 50px;
    right: 40px;
    @media screen and (max-width: 1199px) {
      display: none;
    }
  }

  &__title {
    margin-bottom: 50px;
    @media screen and (max-width: 1199px) {
      margin-bottom: 30px;
    }
  }

  &__wrapper {
    max-width: 1030px;
    padding-bottom: 15px;
    font-size: 1.6rem;
    @media screen and (max-width: 1400px) {
      max-width: 700px;
    }
    @media screen and (max-width: 1199px) {
      max-width: 100%;
    }
  }

  &__paragraph {
    margin-bottom: 20px;
  }
  &__list-item {
    margin-top: 25px;
  }

  &__btn {
    width: 220px;
    height: 50px;
    @media screen and (max-width: 767px) {
      margin: 0 auto;
    }
  }
}

.certificate {
  position: relative;
  padding: 50px 0;
  @media screen and (max-width: 1400px) {
    padding: 30px 0;
  }
  @media screen and (max-width: 1199px) {
    padding: 45px 0;
  }
  @media screen and (max-width: 767px) {
    padding: 30px 0;
  }
  &__title {
    text-align: center;
    margin-bottom: 55px;
  }

  &:after {
    content: '';
    position: absolute;
    width: 1000px;
    height: 1px;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: #DDDDDD;
  }

}

.gallery {
  position: relative;
  padding: 50px 0;
  @media screen and (max-width: 1400px) {
    padding: 30px 0;
  }
  @media screen and (max-width: 1199px) {
    padding: 45px 0;
  }
  @media screen and (max-width: 767px) {
    padding: 30px 0;
  }
  &__title {
    text-align: center;
    margin-bottom: 55px;
  }
  &__slider {
    .item-img {
      object-fit: cover;
    }
  }
}