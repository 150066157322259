@import "color";


.main-btn {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 1.4rem;
  font-weight: 500;
  color: $decor-color;
  border: 1px solid $decor-color;
  border-radius: 4px;
  transition: .3s;
  &:hover {
    background-color: $decor-color;
    color: $white-color;
  }
}

.border-btn {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 1.8rem;
  font-weight: 500;
  color: $btn-color;
  box-shadow: 0 0 0 1px rgba(250, 205, 19, 1);
  background-color:  transparent;
  border-radius: 4px;
  transition: .3s;
  &:hover {
    color: $decor-color;
    background-color: $btn-color;
    @media screen and (max-width: 767px) {
      background-color:  transparent;
      color: $btn-color;
    }
  }
  @media screen and (max-width: 767px) {
    box-shadow: none;
  }
}

.yellow-btn {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 1.8rem;
  font-weight: 500;
  color: $decor-color;
  background-color: $btn-color;
  border-radius: 4px;
  transition: .3s;
  &:hover {
    color: $btn-color;
    box-shadow: 0 0 0 1px rgba(250, 205, 19, 1);
    background-color:  transparent;

    @media screen and (max-width: 767px) {
      background-color:  transparent;
      color: $btn-color;
    }
  }
  @media screen and (max-width: 767px) {
    border: none;
  }
}

.close-btn {
  display: none;
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgba(27, 113, 179, 1);
  transition: .3s;
  &:hover {
    background-color: $btn-color;
  }
  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 2px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 2px;
    background-color: $decor-color;
  }
  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  @media screen and (max-width: 1199px) {
    display: block;
  }
}

.menu-btn {
  width: 40px;
  height: 40px;
  padding: 0 8px;
  border-radius: 6px;
  transition: .3s;
  &:hover {
    box-shadow: 0 0 0 1px rgba(27, 113, 179, 1);
  }
  .line {
    display: block;
    position: relative;
    width: 14px;
    height: 3px;
    border-radius: 3px;
    background-color: $decor-color;
    &:before,
    &:after {
      content: '';
      position: absolute;
      height: 3px;
      left: 0;
      border-radius: 3px;
      background-color: $decor-color;
    }
    &:before {
      width: 22px;
      top: -7px;
    }

    &:after {
      width: 9px;
      bottom: -7px;
    }
  }
  &.active {
    background-color: $btn-color;
    box-shadow: 0 0 0 1px rgba(27, 113, 179, 1);
  }
}

.banner-close {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  top: 20px;
  right: 20px;
  background-color: $btn-color;
  z-index: 5;
  @media screen and (max-width: 1199px) {
    width: 30px;
    height: 30px;
    top: 15px;
    right: 15px;
  }
  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 50%;
    height: 3px;
    border-radius: 3px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $decor-color;
  }

  &:before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:hover {
    background-color: transparent;
    box-shadow: 0 0 0 1px rgba(250, 205, 19, 1);
    &:before,
    &:after {
      background-color: $btn-color;
    }
  }
}

.more-btn {
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 18px 20px;
  font-size: 1.4rem;
  font-weight: 500;
  color: $decor-color;
  border: 1px solid $decor-color;
  border-radius: 4px;
  transition: .3s;
  &:hover {
    background-color: $decor-color;
    color: $white-color;
  }
  @media screen and (max-width: 767px) {
    width: 40px;
    height: 40px;
    padding: 0;
    &:before {
      content: '>';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.active {
      &:before {
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }
  }
  .btn-title {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}

.modal-close {
  position: absolute;
  width: 30px;
  height: 30px;
  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 2px;
    height: 20px;
    border-radius: 2px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $btn-color;
  }

  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}
