@import "color";


.main {
  margin-top: 50px;

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-height: 600px;
    padding: 95px 75px;
    border-radius: 6px;
    background-color: $decor-color;
    overflow: hidden;
    @media screen and (max-width: 1400px) {
      padding: 110px 75px;
    }
    @media screen and (max-width: 1199px) {
      padding: 75px 45px 85px;
    }
    @media screen and (max-width: 767px) {
      padding: 15px;
    }

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: $image-decor;
      z-index: 1;
    }
  }

  &__title {
    max-width: 50%;
    text-transform: uppercase;
    color: $white-color;
    z-index: 5;
    @media screen and (max-width: 1600px) {
      max-width: 60%;
    }
    @media screen and (max-width: 1400px) {
      max-width: 55%;
    }
    @media screen and (max-width: 1199px) {
      max-width: 85%;
    }
  }

  &__subtitle {
    max-width: 450px;
    margin: 50px 0 45px;
    font-size: 2.4rem;
    color: $white-color;
    z-index: 5;
    @media screen and (max-width: 1199px) {
      margin: 30px 0 20px;
    }
    @media screen and (max-width: 767px) {
      margin: 10px 0 20px;
      font-size: 1.2rem;
    }

    .mobile-hide {
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }

  &__btn {
    width: 285px;
    height: 70px;
    z-index: 5;
    @media screen and (max-width: 767px) {
      width: fit-content;
      height: fit-content;
      background-color: transparent;
      color: $btn-color;
      font-size: 1.6rem;
    }
  }

  &__img {
    position: absolute;
    display: block;
    width: 1130px;
    height: 860px;
    top: -70px;
    right: -250px;
    object-fit: contain;

    @media screen and (max-width: 1600px) {
      right: -405px;
    }
    @media screen and (max-width: 1400px) {
      right: -455px;
    }

    @media screen and (max-width: 1199px) {
      width: 1000px;
      height: 700px;
      top: 105px;
      right: -610px;
    }
    @media screen and (max-width: 767px) {
      width: 260px;
      height: 180px;
      top: 20px;
      right: -150px;
    }
  }
  &__services {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 50px 0;
    @media screen and (max-width: 767px) {
      padding-bottom: 30px;
    }
  }

  &__services-item {
    display: flex;
    align-items: center;
    width: 32.25%;
    padding: 35px;
    border-radius: 8px;
    background-color: $white-color;
    box-shadow: $shadow;
    transition: .3s;
    &:hover {
      transform: scale(1.04);
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
      width: 30%;
      padding: 0;
      background-color: transparent;
      box-shadow: none;
    }


    .item-icon {
      width: 40px;
      height: 40px;
      object-fit: contain;
      @media screen and (max-width: 767px) {
        width: 80px;
        height: 80px;
        padding: 25px;
        border-radius: 8px;
        background-color: $white-color;
        box-shadow: $shadow;
      }
    }

    .item-title {
      margin-left: 80px;
      font-size: 2.4rem;
      font-weight: 500;
      color: $decor-color;
      @media screen and (max-width: 1600px) {
        margin-left: 50px;
      }
      @media screen and (max-width: 1400px) {
        margin-left: 40px;
      }
      @media screen and (max-width: 1199px) {
        margin-left: 30px;
        font-size: 1.8rem;
      }
      @media screen and (max-width: 767px) {
        margin: 15px auto 0;
        font-size: 1.4rem;
      }
    }
  }
}

.catalog {
  padding: 45px 0;
  @media screen and (max-width: 1199px) {
    padding: 15px 0;
  }

  &__wrapper {
    position: relative;
  }
  &__title {
    cursor: pointer;
  }
  &__list {
    @media screen and (max-width: 767px) {
      width: calc(100% + 40px);
      margin-left: -20px;
    }
  }

  &__list-wrapper {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-height: 510px;
    padding: 35px 0;
    overflow: hidden;
    transition: .3s;
    @media screen and (max-width: 1199px) {
      padding: 25px 0;
    }

    @media screen and (max-width: 767px) {
      flex-wrap: nowrap;
      overflow: auto;
      padding: 20px;
    }
    &.active {
      max-height: 2000px;

      @media screen and (max-width: 767px) {
        flex-wrap: wrap;
        .catalog__list-item {
          width: 48%;
          margin-right: 0;
          margin-bottom: 20px;
        }
      }
    }

    &:after {
      content: '';
      display: block;
      width: 49.2%;
      @media screen and (max-width: 1199px) {
        width: 32%;
      }
    }
  }

  &__list-item {
    display: flex;
    flex-direction: column;
    width: 23.8%;
    margin-bottom: 35px;
    border-radius: 6px;
    background-color: $white-color;
    box-shadow: $shadow;
    cursor: pointer;
    transition: .3s;
    &:hover {
      transform: scale(1.05);
    }
    @media screen and (max-width: 1199px) {
      width: 32%;
    }
    @media screen and (max-width: 767px) {
      min-width: 44%;
      margin-right: 10px;
      margin-bottom: 0;
    }

    .img-wrapper {
      width: 100%;
      height: 115px;
      border-radius: 6px;
      overflow: hidden;
      backdrop-filter: $image-decor;
      @media screen and (max-width: 767px) {
        height: 90px;
      }
      @media screen and (max-width: 425px) {
        height: 60px;
      }
    }

    .item-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: bottom;
    }
  }
  &__item-title {
    display: flex;
    align-items: center;
    height: 100px;
    padding: 30px;
    font-size: 1.8rem;
    font-weight: 500;
    @media screen and (max-width: 767px) {
      height: 50px;
      padding: 8px 10px 15px;
      font-size: 1.4rem;
    }
  }


  .card {
    display: none;
    width: 100%;
    margin-top: 30px;
    overflow: hidden;
    transition: .5s;
    &.active {
      display: block;
    }

    &__list {
      position: relative;
      padding: 0 70px;
      @media screen and (max-width: 1199px) {
        padding: 0;
      }

      .card-button-prev,
      .card-button-next {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 1px solid $decor-color;
        background-color: $white-color;
        z-index: 10;
        cursor: pointer;
        transition: .3s;
        @media screen and (max-width: 1199px) {
          display: none;
        }
        &:hover {
          background-color: $decor-color;
          &:before {
            border-bottom: 2px solid $white-color;
          }
        }

        &:before {
          content: '';
          position: absolute;
          width: 10px;
          height: 10px;
          top: 50%;
          border-bottom: 2px solid $decor-color;
          transform: translate(0, -50%);
          transition: .3s;
        }
      }
      .card-button-prev {
        left: 0;
        &:hover {
          &:before {
            border-left: 2px solid $white-color;
          }
        }
        &:before {
          left: 20px;
          border-left: 2px solid $decor-color;
          transform: translate(0, -50%) rotate(45deg);
        }
      }
      .card-button-next {
        right: 0;
        &:hover {
          &:before {
            border-right: 2px solid $white-color;
          }
        }
        &:before {
          right: 20px;
          border-right: 2px solid $decor-color;
          transform: translate(0, -50%) rotate(-45deg);
        }
      }
    }

    &__list-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 35px;
      overflow: hidden;
      @media screen and (max-width: 767px) {
        padding: 25px;
      }
    }

    .swiper-slide {
      display: flex;
      align-items: center;
      min-width: 200px;
      width: 100%;
      height: 50px;
      padding: 10px 25px;
      margin-right: 20px;
      border-radius: 25px;
      box-shadow: 0 0 0 1px rgba(27, 113, 179, 1);
      cursor: pointer;
      transition: .3s;
      &:hover {
        background-color: $decor-color;
        span {
          color: $white-color;
        }
      }
      @media screen and (max-width: 767px) {
        min-width: 115px;
        height: 40px;
      }

      &:last-child {
      margin-right: 0;
      }
      span {
        font-size: 1.4rem;
        color: $decor-color;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        transition: .3s;
        @media screen and (max-width: 1199px) {
          font-size: 1.2rem;
        }
      }
    }

    &__desc-wrapper {
      background-color: $white-color;
      box-shadow: $shadow;
      border-radius: 6px;
      overflow: hidden;
    }

    &__headline {
      position: relative;
      max-height: 120px;
      padding: 40px 30px;
      overflow: hidden;
      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(27,113,179,.3);
        z-index: 1;
      }
    }

    &__headline-title {
      z-index: 5;
      @media screen and (max-width: 1199px) {
        display: none;
      }
    }

    &__headline-img {
      display: block;
      position: absolute;
      right: 50px;
      top: -45px;
      width: 310px;
      height: 180px;
      object-fit: contain;
    }

    &__description {
      display: flex;
      flex-direction: column;
      width: 50%;
      padding-left: 30px;
      padding-top: 40px;
      padding-bottom: 70px;
      font-size: 1.6rem;
      @media screen and (max-width: 767px) {
        width: 100%;
        padding: 20px;
        font-size: 1.4rem;
      }
    }

    &__description-title-mobile {
      display: none;
      @media screen and (max-width: 1199px) {
        display: block;
        margin-bottom: 30px;
        font-size: 1.8rem;
      }
    }

    &__description-text {
      max-height: 75px;
      margin: 25px 0 15px;
      overflow: hidden;
      transition: .3s;
      &.active {
        max-height: 3000px;
      }
      @media screen and (max-width: 767px) {
        max-height: 80px;
      }
    }

    .description-more {
      position: relative;
      width: fit-content;
      margin-bottom: 20px;
      cursor: pointer;
      color: $decor-color;
      &:after {
        content: '';
        position: absolute;
        width: 7px;
        height: 7px;
        top: 5px;
        right: -20px;
        transform: rotate(45deg);
        border-right: 2px solid $decor-color;
        border-bottom: 2px solid $decor-color;
        transition: .3s;
      }
      &.active {
        &:after {
          top: 10px;
          transform: rotate(-135deg);
        }
      }
    }

    &__description-link {
      color: $decor-color;
      text-decoration: underline;
      @media screen and (max-width: 425px) {
        text-align: center;
      }
    }

    &__description-btn {
      width: 240px;
      height: 50px;
      margin-top: 45px;
      font-size: 1.4rem;
      @media screen and (max-width: 425px) {
        width: 100%;
      }
    }
  }
}

.form {
  padding: 50px 0;
  @media screen and (max-width: 1400px) {
    padding: 30px 0;
  }
  @media screen and (max-width: 1199px) {
    padding: 45px 0;
  }
  @media screen and (max-width: 767px) {
    padding: 30px 0;
  }

  &__block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 60px 90px;
    border-radius: 8px;
    background-color: $decor-color;
    @media screen and (max-width: 1600px) {
      padding: 60px 70px;
    }
    @media screen and (max-width: 1400px) {
      padding: 60px 50px;
    }
    @media screen and (max-width: 1199px) {
      flex-direction: column;
      padding: 50px 60px;
    }
    @media screen and (max-width: 767px) {
      padding: 30px 20px;
    }
  }

  &__text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 52%;
    @media screen and (max-width: 1600px) {
      width: 43%;
    }
    @media screen and (max-width: 1400px) {
      width: 50%;
    }
    @media screen and (max-width: 1199px) {
      width: 100%;
      flex-direction: column;
    }
    .title {
      width: 320px;
      line-height: 6rem;
      color: $white-color;
      @media screen and (max-width: 1199px) {
        width: 100%;
        text-align: center;
      }
      @media screen and (max-width: 767px) {
        line-height: normal;
        margin-bottom: 20px;
      }
    }
  }

  &__subtitle-desktop {
    width: 380px;
    font-size: 2.2rem;
    color: $white-color;
    @media screen and (max-width: 1600px) {
      width: 180px;
      font-size: 1.8rem;
    }

    @media screen and (max-width: 1400px) {
      width: 160px;
      font-size: 1.6rem;
    }

    @media screen and (max-width: 1199px) {
      width: 330px;
      margin: 30px 0 40px;
      text-align: center;
    }

    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  &__subtitle-mobile {
    display: none;
    font-size: 1.6rem;
    color: $white-color;
    @media screen and (max-width: 767px) {
      display: block;
    }
  }

  &__body {
    display: flex;
    @media screen and (max-width: 1199px) {
      max-width: 610px;
      width: 100%;
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__body-input {
    width: 350px;
    height: 50px;
    padding: 0 20px;
    margin-right: 25px;
    border-radius: 4px;
    background-color: $white-color;
    color: $decor-color;
    font-size: 1.4rem;
    font-weight: 500;
    &::placeholder {
      color: $decor-color;
      font-size: 1.4rem;
      font-weight: 500;
    }
    @media screen and (max-width: 1400px) {
      width: 290px;
    }
    @media screen and (max-width: 1199px) {
      flex: 1;
    }
    @media screen and (max-width: 767px) {
      flex: none;
      width: 100%;
      max-width: 320px;
      margin: 30px 0 20px;
    }
  }

  &__body-btn {
    width: 165px;
    height: 50px;
    @media screen and (max-width: 767px) {
      width: 100%;
      max-width: 320px;
    }
  }
}

.advantage {
  padding: 50px 0;
  @media screen and (max-width: 1400px) {
    padding: 40px 0;
  }
  @media screen and (max-width: 1199px) {
    padding: 35px 0;
  }
  @media screen and (max-width: 767px) {
    padding: 25px 0;
  }

  &__title {
    font-size: 3rem;
    font-weight: 500;
    transition: .3s;
    &:hover {
      color: $decor-color;
    }
    @media screen and (max-width: 1199px) {
      font-size: 2.4rem;
    }
    @media screen and (max-width: 767px) {
      font-size: 2rem;
    }
  }

  &__btn {
    display: block;
    position: relative;
    font-size: 1.4rem;
    color: $decor-color;
    transition: .3s;
    @media screen and (max-width: 767px) {
      color: $decor-color;
      border: 1px solid $decor-color;
      border-radius: 4px;
      transition: .3s;
      width: 40px;
      height: 40px;
      padding: 0;
      &:hover {
        background-color: $decor-color;
        color: $white-color;
      }
      &:before {
        content: '>';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &.active {
        &:before {
          transform: translate(-50%, -50%) rotate(90deg);
        }
      }
    }
    .btn-title {
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }

  &__list {
    position: relative;
    display: flex;
    justify-content: space-between;
    max-height: 210px;
    margin-top: 35px;
    overflow: hidden;
    transition: .3s;
    @media screen and (max-width: 1199px) {
      max-height: 430px;
      flex-wrap: wrap;
    }
    @media screen and (max-width: 767px) {
      max-height: 245px;
    }
  }

  &__list-item {
    display: flex;
    width: 24%;
    height: 200px;
    margin-bottom: 20px;
    border-radius: 6px;
    background-color: $white-color;
    box-shadow: $shadow;
    @media screen and (max-width: 1400px) {
      flex-direction: column;

    }
    @media screen and (max-width: 1199px) {
      width: 49%;
    }
    @media screen and (max-width: 767px) {
      height: 110px;
      margin-bottom: 15px;
    }

    .img-wrapper {
      max-width: 50%;
      border-radius: 6px;
      overflow: hidden;
      @media screen and (max-width: 1400px) {
        max-width: 100%;

      }
      @media screen and (max-width: 1199px) {

        max-height: 50%;
      }
    }

    .item-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .item-title {
      margin: 30px 25px;
      font-size: 1.8rem;
      font-weight: 600;
      @media screen and (max-width: 1199px) {
        margin: 20px 30px 30px;
        max-width: 160px;
        height: 50%;
      }
      @media screen and (max-width: 767px) {
        margin: 10px;
        font-size: 1.4rem;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}
